<template>
  <div>
    <a-page-header title="工单管理" class="hearStyle" />
    <div class="searchBox">
      <div class="searchBox_form">
        <div class="displayFlex">
          <div class="searchBox_form_type">工单信息：</div>
          <div class="marginLeft flex1">
            <a-form layout="inline" :labelCol="{ style: 'width: 94px' }">
              <a-form-item label="工单编号：">
                <a-input v-model.trim="searchForm.workNumber" style="width: 200px" placeholder="请输入工单编号" />
              </a-form-item>
              <a-form-item label="工单状态：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  mode="multiple"
                  :maxTagCount="1"
                  :maxTagTextLength="5"
                  style="width: 200px"
                  placeholder="请选择工单状态"
                  v-model="searchForm.workStateList"
                >
                  <a-select-option v-for="item in workerStatusList" :key="item.demandState">
                    {{ item.demandStateName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="项目信息：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  mode="multiple"
                  :maxTagCount="1"
                  :maxTagTextLength="5"
                  style="width: 200px"
                  placeholder="请选择项目信息"
                  v-model="searchForm.projectIdList"
                >
                  <a-select-option v-for="item in projectMsgList" :key="item.projectId">
                    {{ item.projectName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="企业信息：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  mode="multiple"
                  :maxTagCount="1"
                  :maxTagTextLength="5"
                  show-search
                  v-model="searchForm.enterpriseIdList"
                  placeholder="输入企业名称以选择"
                  style="width: 200px"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleSearch"
                >
                  <a-select-option v-for="item in enterpriseMsg" :key="item.enterpriseId">
                    {{ item.enterpriseName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="需求来源：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="sourceChange"
                  mode="multiple"
                  :maxTagCount="1"
                  :maxTagTextLength="5"
                  style="width: 200px"
                  placeholder="请选择需求来源"
                  v-model="searchForm.demandSourceList"
                >
                  <a-select-option v-for="item in demandSourceList" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item v-if="searchForm.demandSourceList.length" label="需求类型：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  mode="multiple"
                  :maxTagCount="1"
                  :maxTagTextLength="5"
                  style="width: 200px"
                  placeholder="请先选择需求来源"
                  v-model="searchForm.demandTypeList"
                >
                  <a-select-option v-for="item in demandTypeList" :key="item.demandType">
                    {{ item.demandTypeName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="需求提出日期：">
                <a-range-picker
                  :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                  :placeholder="['最早提出日期', '最晚提出日期']"
                  v-model="orderTime"
                  format="YYYY-MM-DD"
                />
              </a-form-item>
            </a-form>
          </div>
        </div>
        <div v-show="expandShow" class="displayFlex">
          <div class="searchBox_form_type">处理信息：</div>
          <div class="marginLeft flex1">
            <a-form layout="inline" :labelCol="{ style: 'width: 94px' }">
              <a-form-item label="需求发起人：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  mode="multiple"
                  :maxTagCount="1"
                  :maxTagTextLength="5"
                  show-search
                  v-model="searchForm.createIdList"
                  placeholder="输入查询需求发起者"
                  style="width: 200px"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="initiatorHandleSearch"
                >
                  <a-select-option v-for="item in demandInitiateList" :key="item.userId">
                    {{ item.userName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="服务负责人：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  mode="multiple"
                  :maxTagCount="1"
                  :maxTagTextLength="5"
                  show-search
                  v-model="searchForm.principalIdList"
                  placeholder="输入查询服务负责人"
                  style="width: 200px"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="headHandleSearch"
                >
                  <a-select-option v-for="item in serviceHeadList" :key="item.userId">
                    {{ item.userName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="服务协作人：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  mode="multiple"
                  :maxTagCount="1"
                  :maxTagTextLength="5"
                  show-search
                  v-model="searchForm.cooperationIdList"
                  placeholder="输入查询服务协作人"
                  style="width: 200px"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="collaboratorHandleSearch"
                >
                  <a-select-option v-for="item in serviceCollaborateList" :key="item.userId">
                    {{ item.userName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <div v-show="expandShow" class="displayFlex">
          <div class="searchBox_form_type">归档信息：</div>
          <div class="marginLeft flex1">
            <a-form layout="inline" :labelCol="{ style: 'width: 94px' }">
              <a-form-item label="需求分类：">
                <el-cascader
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  placeholder="请选择需求分类"
                  v-model="searchForm.classifyList"
                  style="width: 200px"
                  size="small"
                  :options="demandClassifyList"
                  :props="{ multiple: true, value: 'classifyId', label: 'classifyName', children: 'childList' }"
                  collapse-tags
                  clearable
                ></el-cascader>
              </a-form-item>
              <a-form-item label="需求服务类型：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  :maxTagCount="1"
                  :maxTagTextLength="5"
                  mode="multiple"
                  style="width: 200px"
                  placeholder="请选择需求服务类型"
                  v-model="searchForm.demandServerTypeList"
                >
                  <a-select-option v-for="item in demandServiceTypeList" :key="item.demandServerType">
                    {{ item.demandServerTypeName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="是否有效需求：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  :maxTagCount="1"
                  :maxTagTextLength="5"
                  mode="multiple"
                  style="width: 200px"
                  placeholder="请选择是否有效需求"
                  v-model="searchForm.isReasonableDemandList"
                >
                  <a-select-option v-for="item in validOrNotDemand" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="实际解决情况：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  :maxTagCount="1"
                  :maxTagTextLength="5"
                  mode="multiple"
                  style="width: 200px"
                  placeholder="请选择实际解决情况"
                  v-model="searchForm.solutionInformationList"
                >
                  <a-select-option v-for="item in realitySituationList" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <div v-show="expandShow" class="displayFlex">
          <div class="searchBox_form_type">回访信息：</div>
          <div class="marginLeft flex1">
            <a-form layout="inline" :labelCol="{ style: 'width: 94px' }">
              <a-form-item label="回访方式：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  mode="multiple"
                  :maxTagCount="1"
                  style="width: 200px"
                  @change="returnVisitTypeChange"
                  placeholder="请选择回访方式"
                  v-model="searchForm.returnVisitTypeList"
                >
                  <a-select-option v-for="item in followUpTypeList" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="服务满意度：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  mode="multiple"
                  :maxTagCount="1"
                  style="width: 200px"
                  placeholder="请选择服务满意度"
                  v-model="searchForm.isSatisfactionList"
                >
                  <a-select-option v-for="item in serviceSatisfactionList" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item v-show="showReturnVisitType" label="响应是否及时：">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  mode="multiple"
                  :maxTagCount="1"
                  style="width: 200px"
                  placeholder="请选择响应是否及时"
                  v-model="searchForm.isPromptResponseList"
                >
                  <a-select-option v-for="item in replytimelyList" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <div>
          <a-button type="primary" @click="exportFile"> 批量导出 </a-button>
        </div>
      </div>
      <div class="searchBox_but">
        <a-button type="primary" @click="search" style="margin-top: 3px">
          <a-icon type="search" />
          查询
        </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">
          <a-icon type="redo" />
          重置
        </a-button>
        <div
          @click="
            () => {
              expandShow = !expandShow;
            }
          "
          v-show="!expandShow"
          class="expandBox"
        >
          展开 <a-icon type="down" :style="{ color: '#1890ff' }" />
        </div>
        <div
          @click="
            () => {
              expandShow = !expandShow;
            }
          "
          v-show="expandShow"
          class="expandBox"
        >
          收起 <a-icon type="up" :style="{ color: '#1890ff' }" />
        </div>
      </div>
    </div>
    <a-table
      @change="pageChange"
      :columns="columns"
      style="margin-top: 10px"
      :pagination="pagination.total ? pagination : false"
      :data-source="tableData"
      :rowKey="(record) => record.id"
      bordered
      :scroll="{ x: 3200 }"
    >
      <span slot="project_name_list" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{ record.project_name_list }}
          </template>
          <span class="field">{{ record.project_name_list }}</span>
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="checkDetail(record)" size="small"> 查看详情 </a-button>
      </span>
    </a-table>
  </div>
</template>
<script>
import moment from 'moment';
import {
  requirementPoollist,
  requirementDemandtypeall,
  requirementDemandstate,
  requirementProject,
  requirementEnterprise,
  requirementHandleMsg,
  requirementClassify,
  requirementDemandServertype,
  requirementExport,
} from '@/api/demandPoolShunyi';
import { columns, searchForm, dataList } from './index';
import Vue from 'vue';
import { Cascader } from 'element-ui';
Vue.use(Cascader);
export default {
  name: 'workOrder',
  data() {
    let searchFormData = JSON.parse(JSON.stringify(searchForm));
    return {
      columns,
      expandShow: false, //true展开
      orderTime: [],
      searchForm: {
        ...searchFormData,
      },
      tableData: [],
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      showReturnVisitType:false,//是否展示响应是否及时
      ...dataList,
    };
  },
  mounted() {
    // 获取列表数据
    this.getTableList();

    this.getProject();
    this.getDemandstate();
    this.getClassify();
    this.getServertype();
  },
  methods: {
    async getTableList() {
      let searchForm = JSON.parse(JSON.stringify(this.searchForm));
      let list = [];
      if (searchForm.classifyList.length) {
        searchForm.classifyList.forEach((item) => {
          item.forEach((i) => {
            list.push(i);
          });
        });
      }
      if (this.orderTime.length) {
        searchForm.startTime = moment(this.orderTime[0]).format('YYYY-MM-DD');
        searchForm.endTime = moment(this.orderTime[1]).format('YYYY-MM-DD');
      } else {
        searchForm.startTime = '';
        searchForm.endTime = '';
      }
      searchForm.classifyList = list;
      const res = await requirementPoollist({
        ...searchForm,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      });
      if (res.code == 200) {
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
      }
    },
    //获取项目信息
    async getProject() {
      const res = await requirementProject();
      if (res.code == 200) {
        this.projectMsgList = res.data;
      }
    },
    //获取需求分类
    async getClassify() {
      const res = await requirementClassify();
      if (res.code == 200) {
        this.demandClassifyList = res.data;
      }
    },
    //获取工单状态
    async getDemandstate() {
      const res = await requirementDemandstate();
      if (res.code == 200) {
        this.workerStatusList = res.data;
      }
    },
    //获取企业信息
    async handleSearch(value) {
      const res = await requirementEnterprise({ enterpriseName: value });
      if (res.code == 200) {
        this.enterpriseMsg = res.data;
      }
    },
    //需求发起者select输入框
    async initiatorHandleSearch(value) {
      const res = await requirementHandleMsg({ userName: value, userType: 1 });
      if (res.code == 200) {
        this.demandInitiateList = res.data;
      }
    },
    //服务负责人select输入框
    async headHandleSearch(value) {
      const res = await requirementHandleMsg({ userName: value, userType: 2 });
      if (res.code == 200) {
        this.serviceHeadList = res.data;
      }
    },
    //服务协作人select输入框
    async collaboratorHandleSearch(value) {
      const res = await requirementHandleMsg({ userName: value, userType: 3 });
      if (res.code == 200) {
        this.serviceCollaborateList = res.data;
      }
    },
    //需求服务类型
    async getServertype() {
      const res = await requirementDemandServertype();
      if (res.code == 200) {
        this.demandServiceTypeList = res.data;
      }
    },
    //需求来源change事件
    async sourceChange() {
      const res = await requirementDemandtypeall({ demandSourceList: this.searchForm.demandSourceList });
      if (res.code == 200) {
        this.demandTypeList = res.data;
      }
    },
    // 回访方式change事件
    returnVisitTypeChange() {
        let type=false
      this.searchForm.returnVisitTypeList.forEach((item) => {
        if(item==1){
            type=true
        }
      });
      this.showReturnVisitType=type
    },
    search() {
      this.pagination.current = 1;
      this.getTableList();
    },
    resetSearchForm() {
      this.searchForm = this.$options.data().searchForm;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.orderTime = [];
      this.getTableList();
    },
    checkDetail(record) {
      this.$router.push({
        path: '/demand-pool-shunyi/workOrder/workOrderDetail',
        query: {
          id: record.workOrderId,
        },
      });
    },
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getTableList();
    },
    //批量导出
    async exportFile() {
      if (this.orderTime.length) {
        this.searchForm.startTime = moment(this.orderTime[0]).format('YYYY-MM-DD');
        this.searchForm.endTime = moment(this.orderTime[1]).format('YYYY-MM-DD');
      } else {
        this.searchForm.startTime = '';
        this.searchForm.endTime = '';
      }
      try {
        const res = await requirementExport({ ...this.searchForm });
        const blob = new Blob([res.data]);
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objectUrl;
        a.setAttribute('download', '工单管理.xlsx');
        a.click();
      } catch (e) {
        this.$message.error('您要导出的数据超过10000条，请缩小筛选范围后重试');
      }
    },
  },
  // 进入路由添加缓存
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch('operationCachedRoutes/updateAliveRoutes', ['workOrder']);
    });
  },
};
</script>
<style lang="scss" scoped>
.searchBox {
  display: flex;
}

.expandBox {
  color: #1890ff;
  line-height: 40px;
  text-align: right;
  cursor: pointer;
}

.searchBox_but {
  margin-left: 20px;
}

.searchBox_form {
  flex: 1;
}

.searchBox_form_type {
  line-height: 39.9999px;
}

.marginLeft {
  margin-left: 5px;
}

.flex1 {
  flex: 1;
}

.displayFlex {
  display: flex;
}
</style>